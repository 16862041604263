.section {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.section h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.markdown {
  font-family: 'PT Root UI';
  font-size: 1.1rem;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

.txnInfoText {
  color: var(--brand-gray-light-text);
  margin-left: -0.1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .txnInfoText {
    align-items: flex-start;
    margin-top: 1rem;
  }

  .txnInfoIcon {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
  }
}

.txnInfoIcon {
  height: 18px;
  width: 18px;
  opacity: 0.5;
}

.txnInfoIconWrapper {
  width: 25px;
  display: flex;
  align-items: center;
}
