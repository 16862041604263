@media (max-width: 992px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0.5rem;
  }
}

@media (max-width: 660px) {
  .emergencySettleWrapper {
    text-align: center;
    margin-left: 0rem;
  }
}

.emergencySettleButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
.emergencySettleButton:disabled {
  color: #8c8d92;
  text-decoration: none;
  cursor: default;
}
