.delegateHover {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.scaleIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.scaleIcon {
  height: 22px;
  width: 22px;
}

.address {
  font-weight: bold;
  cursor: pointer;
}

.externalLinkIcon {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.transactionHashWrapper {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: fit-content;
}
