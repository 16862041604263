.label {
  opacity: 0.5;
}

.argument {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.row {
  display: flex;
}

.value {
  font-size: 22px;
  font-weight: bold;
  color: var(--brand-cool-dark-text);
  margin-bottom: 0.5rem;
  word-break: break-all;
}

.argValue {
  max-width: 50%;
  word-break: break-all;
}
