.wrapper {
  width: max-content;
  height: fit-content;
}

.spinnerWrapper {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spinner {
  display: flex;
  width: 100%;
  justify-content: center;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.titleWrapper h1 {
  color: black;
  font-family: 'Londrina Solid';
  font-size: 24px;
  margin-top: 0.45rem;
  margin-left: 0.5rem;
}

.nounWrapper {
  height: 42px;
  width: 42px;
}

.nounInfoWrapper {
  margin-top: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;

  color: var(--brand-gray-dark-text);
}

.bold {
  font-weight: bold;
  margin-left: 0.25rem;
}

.icon {
  margin-right: 7px;
}

.cta {
  color: var(--brand-gray-light-text);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
}

.currentHolder {
  margin-bottom: 1rem;
}
