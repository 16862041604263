.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  .wrapper {
    justify-content: flex-start;
  }
}

.voteStatusWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
