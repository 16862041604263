.voterLink {
  font-weight: bold;
  cursor: pointer;
}

.delegateHover {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}
