.wrapper {
  margin-top: 1rem;
}

.label {
  opacity: 0.5;
}

.form {
  border-radius: 15px;
  width: 100%;
  height: 3rem;
  font-size: 22px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--brand-cool-dark-text);
}
